/*@import "~materialize-css/dist/css/materialize.min.css";*/
/*@import "~bootstrap/dist/css/bootstrap.css";*/
/*@import '~bootstrap/dist/css/bootstrap.min.css';*/
@import '~react-datepicker/dist/react-datepicker.css';
.mt-120 {
  margin-top: 120px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pt-lable {
  padding-top: 1.5rem;
}
.loading {
  font-weight: 300;
  font-size: 0.75rem;
  cursor: pointer;
  color: #909090;
}
.h-50px {
  height: 50px;
}
#dt-basic-example_filter label {
  display: flex;
}
.active,
.nav-menu li > ul li a.active:hover {
  color: fff !important;
}
.auditlist-collapse-span span {
  font-weight: bold;
  line-height: 2rem;
}
.pagination {
  float: right;
}
.line-top-dotted {
  margin-top: 2rem;
  text-align: center;
  padding-top: 2rem;
  border-top: 1px dashed rgba(29, 29, 29, 0.07);
}
.h2 {
  font-size: 1.375rem !important;
  line-height: 1.3 !important;
  font-weight: 400 !important;
}
.border-input-change {
  border: 1px solid #ccbfdf;
  border-radius: 4px;
}
/*
.alert-absolute{
    right: 0;
    bottom: 0;
    z-index: 1100;
    position: absolute !important;
    display: block;
    position: fixed !important;
}
.modal-loading{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2050;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    transition: opacity .15s linear;
}
*/
#authform div.msg {
  min-height: 1px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.panel-tag {
  /*display: contents;*/
}
.react-datepicker {
  display: flex !important;
}
#app-eventlog > div:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

.bd-gray-light {
  background-color: rgba(80, 80, 80, 0.07);
}
thead th {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial !important;
  font-size: 0.8125rem !important;
  letter-spacing: 0.1px !important;
  background-color: #7a59ad !important;
  color: white !important;
  border-top: 0 !important;
  border-bottom: 0 !important;

  padding: 0.75rem !important;
}
.modal-body.audit-detail {
  font-size: 14px;
}
.modal-body.audit-detail span {
  font-weight: bold;
}
.col-ldap-buttons {
  min-width: 180px !important;
  text-align: center;
}
.icon-align {
  display: table-cell;
  vertical-align: inherit;
  font-size: 0.85rem !important;
  line-height: 1.43;
}
.css-1ex1afd-MuiTableCell-root,
.css-pdct74-MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial !important;
  font-size: 0.85rem !important;
  letter-spacing: 0.1px !important;
}
thead th.MuiTableCell-root {
  padding-top: 0.15rem !important;
  padding-bottom: 0.15rem !important;
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  color: #fff;
  background-color: #886ab5 !important;
  border-color: #886ab5 !important;
  display: inline-block;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.47;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
  color: #fff;
  background-color: red;
  border-color: #6e4e9e;
  text-decoration: none;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
  color: #fff;
  background-color: #7453a6 !important;
  border-color: #6e4e9e !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root.focus,
.css-1w1rijm-MuiButtonBase-root-MuiButton-root:focus {
  color: #fff !important;
  background-color: #7453a6 !important;
  border-color: #6e4e9e !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(154, 128, 192, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(154, 128, 192, 0.5) !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: rgba(255, 255, 255, 0.87) !important;
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: rgba(255, 255, 255, 0.87) !important;
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  color: rgba(255, 255, 255, 0.6) !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: rgba(255, 255, 255, 0.6) !important;
}

p.MuiTablePagination-selectLabel,
p.MuiTablePagination-displayedRows {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.alert-header {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 1;
  order: 3;
}

.panel-tag {
  display: contents;
}

.pagination-mt {
  margin-top: 1.5rem;
  height: 4rem;
  vertical-align: bottom;
}

.pagination-input {
  float: right;
  width: 110px;
  margin-left: 25px;
  margin-bottom: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.page-link {
  height: calc(1.47em + 1rem + 2px);
  padding: 0.6rem 0.875rem 0rem 0.875rem;
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.4;
}
